.hero-row {
    display: flex;
    flex-direction: row;
    width: 80vw;
    justify-content: space-around;
    font-size: calc(0.625rem + 2vmin);
    color: white;
}

.hero-down-arrows {
    font-size: calc(15rem + 2vmin);
    color: white;
}

@-webkit-keyframes swinging{
    0%{-webkit-transform: rotate(10deg);}
    50%{-webkit-transform: rotate(-5deg)}
    100%{-webkit-transform: rotate(10deg);}
}

@keyframes swinging{
    0%{transform: rotate(10deg);}
    50%{transform: rotate(-5deg)}
    100%{transform: rotate(10deg);}
}

@media (prefers-reduced-motion: no-preference) {
    .swing-image{
        -webkit-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-animation: swinging 2s ease-in-out forwards infinite;
        animation: swinging 2s ease-in-out forwards infinite;
    }
}
