.slideTwoText {
    color: white;
    font-size: calc(0.938rem + 2vmin);
    margin: 2rem 0;
}

.slideTwoImgTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
