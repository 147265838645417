.slideFourText {
    color: white;
    font-size: calc(0.938rem + 2vmin);
    margin: 2rem 0;
}

.slideFourImgFourContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}