.slideSixText {
    color: white;
    font-size: calc(0.938rem + 2vmin);
    margin: 1rem 0;
    text-align: center;
}

.slideSixImgContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}